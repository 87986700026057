import React, { useEffect, useState } from 'react';
import icon from '../images/up-arrow.svg';
import { isTouchScreen } from '../utils/utils';

function ToTop() {
  const [show, setShow] = useState(false);

  if (isTouchScreen) {
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  }

  const handleScroll = () => {
    if (window.scrollY > 700) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const toTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
    <div
      onClick={toTop}
      className={'to-top is-hidden-tablet ' + (show ? 'show' : '')}
    >
      <img style={{ margin: '6px' }} src={icon} />
    </div>
  );
}

export default ToTop;
