import React from 'react';
import MenuLink from './MenuLink';
import Dropdown from './Dropdown';
import { terapi, metoder, info, hæfter } from '../../../config/menuLinks';

function Menu() {
  return (
    <div
      className="navbar-start"
      style={{ flexGrow: 1, justifyContent: 'center' }}
    >
      <MenuLink path="/" text="Forside" />
      <Dropdown title="Jeg arbejder med" links={terapi} />
      <Dropdown title="Metoder" links={metoder} />
      <MenuLink path="/udgivelser/" text="Udgivelser" />
      <Dropdown title="Hæfter" links={hæfter} />
      <MenuLink path="/blog/" text="Blog" />
      <Dropdown title="Info" links={info} />
      <MenuLink path="/kontakt/" text="Kontakt" />
      <MenuLink path="/profil/" text="Profil" />
    </div>
  );
}

export default Menu;
