import { Link } from 'gatsby';
import React from 'react';

function MenuLink({ path, text }) {
  return (
    <Link to={path} className="navbar-item" activeClassName="is-active">
      {text}
    </Link>
  );
}

export default MenuLink;
