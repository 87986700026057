import React from 'react';
import icon from '../../images/footer.svg';

function Footer() {
  return (
    <footer className="footer has-text-centered">
      <img
        style={{ maxHeight: '60px' }}
        src={icon}
        alt="Medlem af Dansk Psykolog Forening"
      />
    </footer>
  );
}

export default Footer;
