import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

function Hero() {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          subTitle
        }
      }
    }
  `);

  return (
    <div className="hero is-info">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-4 is-size-5-mobile">
            {data.site.siteMetadata.title}
          </h1>
          <h2 className="subtitle has-text-weight-medium is-6 is-size-7-mobile">
            {data.site.siteMetadata.subTitle}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Hero;
