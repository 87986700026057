import React, { useState } from 'react';
import MenuLink from './MenuLink';

function Dropdown({ title, links }) {
  const [active, setActive] = useState(false);
  const linksList = links.map((data, index) => (
    <MenuLink key={index} {...data} />
  ));

  const handleClick = () => {
    if (window.innerWidth <= 1023) setActive(!active);
  };

  return (
    <div
      className={
        'navbar-item has-dropdown is-hoverable ' + (active ? 'is-active' : '')
      }
    >
      <p onClick={handleClick} className="navbar-link-new">
        {title}
      </p>

      <div className="navbar-dropdown">{linksList}</div>
    </div>
  );
}

export default Dropdown;
