import React, { useState, useEffect } from 'react';
import Menu from '../navbar/Menu';

function Navbar() {
  const [active, setActive] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  let el = null;
  let headerHeight = 0;

  useEffect(() => {
    el = document.querySelector('#header');
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);
    headerHeight = el.clientHeight;
    return () => unmount();
  }, []);

  const unmount = () => {
    window.removeEventListener('scroll', handleScroll);
    window.removeEventListener('resize', handleResize);
  };

  const handleScroll = () => {
    if ((window.scrollY > headerHeight) & (window.innerWidth >= 1024)) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  const handleResize = () => {
    headerHeight = el.clientHeight;
  };

  return (
    <div className="nav-wrapper">
      <nav
        className={'navbar ' + (isFixed ? 'is-fixed-top' : '')}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div
            onClick={() => setActive(!active)}
            role="button"
            className={'navbar-burger burger ' + (active ? 'is-active' : '')}
            aria-label="menu"
            aria-expanded="false"
            data-target="navMenu"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>

          <div
            id="navMenu"
            className={'navbar-menu ' + (active ? 'is-active' : '')}
          >
            <Menu />
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
