import React from 'react';

import Navbar from './layout/Navbar';
import Header from './layout/Header';
import Footer from './layout/Footer';
import ToTop from './ToTop';
import '../css/mystyles.scss';
import { Helmet } from 'react-helmet';

function Layout({ children, showHeader = true }) {
  return (
    <>
      <Helmet>
        <script defer data-domain="jenshenrikthomsen.dk" src="/js/script.js" />
      </Helmet>

      <Header showHeader={showHeader} />
      <Navbar />
      <main className="section">
        <article className="container">{children}</article>
      </main>
      <Footer />
      <ToTop />
    </>
  );
}

export default Layout;
